<template src="./Login.html"></template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import LoginService from "@/services/Login";
import { nodeInstance, phpInstance } from "@/plugins/axios";
import userService from "@/services/User";
import { roleName } from "@/utils/constants";
import locationService from "../../services/Locations";

export default {
  name: "login",
  components: { ArrobaMedellinLogo },
  data() {
    return {
      infoLogin: {
        document: "",
        password: "",
      },
      dataUser: {
        names: "",
        surnames: "",
        email: "",
        city: "",
        province: "",
        documentType: "",
        document: "",
        password: "",
        gender: "",
      },
      showLoginState: true,
      showSignUp: false,
      successful: false,
      loginError: false,
      loginErrorMessage: "",
      roleName,
      getStates: [],
      getCities: [],
      getCountries: ["Colombia"],
      country: "",
      userRegister: null,
    };
  },
  validations() {
    const infoLogin = {
      document: { required },
      password: { required, minLength: minLength(6) },
    };
    const dataUser = {
      names: { required },
      surnames: { required },
      email: { email, required },
      city: { required },
      province: { required },
      documentType: { required },
      document: { required },
      gender: { required },
    };
    if (this.showLoginState) {
      return { infoLogin };
    }
    if (this.showSignUp) {
      return { dataUser };
    }
  },
  created() {
    this.getStatesChange();
    this.country = this.getCountries[0];
  },
  methods: {
    goToHome() {
      const ram = navigator.deviceMemory;
      if (ram === 8) {
        this.$store.dispatch("fetchInitialHome", true);
      } else if (
        navigator.platform.indexOf("Mac") === 0 ||
        navigator.platform === "iPhone"
      ) {
        this.$store.dispatch("fetchInitialHome", false);
      } else {
        this.$store.dispatch("fetchInitialHome", false);
      }
    },
    showLogin() {
      this.showLoginState = true;
      this.showSignUp = false;
      this.dataUser = {};
    },
    async login() {
      this.$store.dispatch("fetchLoading", true);
      try {
        const auth = await LoginService.auth({
          document: this.infoLogin.document,
          password: this.infoLogin.password,
        });
        if (auth.message) {
          this.loginError = true;
          this.loginErrorMessage = auth.message;
          return false;
        }
        const {
          tokenNode,
          tokenPhp,
          userController: { flag },
          users: [user],
        } = auth;
        nodeInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${tokenNode}`;
        phpInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${tokenPhp}`;
        this.$store.dispatch("fetchTokenControllerNode", tokenNode);
        this.$store.dispatch("fetchTokenControllerPhp", tokenPhp);
        this.$store.dispatch("fetchUserRole", auth.users);
        if (auth.users.length === 0) {
          throw "El usuario no tiene roles";
        }
        if (flag == 0) {
          this.$router.push("/changePasswordUserController");
        } else {
          const infoUser = await LoginService.getUserAuthByRol({
            userId: JSON.stringify(user.id),
            token: tokenPhp,
          });
          if (!infoUser.user.institutionId) {
            this.$router.push("/sinInstituciones");
          } else {
            this.$store.dispatch("fetchUser", infoUser.user);
            this.$store.dispatch("fetchJwt", infoUser.tokenNode);
            this.$store.dispatch("fetchToken", infoUser.tokenPhp);
            nodeInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${infoUser.tokenNode}`;
            phpInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${infoUser.tokenPhp}`;
            const flagUser = infoUser.user.arrobaId;
            if (flagUser) {
              this.$store.dispatch("fetchAuth", true);
              this.goToHome();
            } else {
              if (
                infoUser.role.name === this.roleName.TEACHER ||
                infoUser.role.name === this.roleName.STUDENT
              ) {
                this.$store.dispatch("fetchAuth", false);
                this.$store.dispatch("fetchUserEmail", infoUser.user.document);
                this.$router.push("/nuevousuario");
              } else {
                this.$store.dispatch("fetchAuth", true);
                this.goToHome();
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
        this.songs.ALERT.play();
        this.loginError = true;
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
    showRegister() {
      this.showLoginState = false;
      this.showSignUp = true;
    },
    showLogon() {
      this.showLoginState = true;
      this.showSignUp = false;
      this.dataUser = {};
    },
    async signIn() {
      try {
        const ojbData = {
          documento: this.dataUser.document,
          tipo_documento: this.dataUser.documentType,
          nombres: this.dataUser.names,
          email_contacto: this.dataUser.email,
          apellidos: this.dataUser.surnames,
          departamento: this.dataUser.province,
          ciudad: this.dataUser.city,
          clave: this.dataUser.document,
          genero: this.dataUser.gender,
        };
        const user = await userService.registerUserCampus(ojbData);
        this.userRegister = user;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Usuario creado",
          position: "is-bottom",
          type: "is-primary",
        });
        await userService.sendRegistrationConfirmation(user.id);
        this.successful = true;
        this.showLoginState = false;
        this.showSignUp = false;
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async forwardMailCredentials() {
      try {
        await userService.sendRegistrationConfirmation(this.userRegister.id);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Correo enviado",
          position: "is-bottom",
          type: "is-primary",
        });
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message ?? "Ocurrio un error",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async getStatesChange() {
      try {
        this.getStates = await locationService.getStates(this.getCountries[0]);
      } catch (e) {
        console.error("error", e);
      }
    },
    async getCitiesChange(country, state) {
      try {
        this.getCities = await locationService.getCities(country, state);
      } catch (e) {
        console.error("error", e);
      }
    },
    signInTwo() {
      this.showSignUp = false;
      this.successful = false;
      this.showLoginState = true;
      this.dataUser = {};
    },
  },
};
</script>

<style lang="scss" src="./Login.scss" scoped></style>
